import React, { useState, useRef } from "react";
import axios from "axios";
import NavBar from "./partials/NavBar";
const UserForm = () => {
  const [showModal, setShowModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false); // Aggiunto showSuccessMessage
  const [errorMessage, setErrorMessage] = useState("");
  const [formData, setFormData] = useState({
    nome: "",
    cognome: "",
    azienda: "",
    ruolo: "",
    nazione: "",
    mail: "",
    tel: "",
    web: "",
    interessi: [],
    descrizione: "",
    privacy: false,
  });

  const countries = [
    { name: "Albania", code: "AL" },
    { name: "Åland Islands", code: "AX" },
    { name: "Algeria", code: "DZ" },
    { name: "American Samoa", code: "AS" },
    { name: "Andorra", code: "AD" },
    { name: "Angola", code: "AO" },
    { name: "Anguilla", code: "AI" },
    { name: "Antarctica", code: "AQ" },
    { name: "Antigua and Barbuda", code: "AG" },
    { name: "Argentina", code: "AR" },
    { name: "Armenia", code: "AM" },
    { name: "Aruba", code: "AW" },
    { name: "Australia", code: "AU" },
    { name: "Austria", code: "AT" },
    { name: "Azerbaijan", code: "AZ" },
    { name: "Bahamas (the)", code: "BS" },
    { name: "Bahrain", code: "BH" },
    { name: "Bangladesh", code: "BD" },
    { name: "Barbados", code: "BB" },
    { name: "Belarus", code: "BY" },
    { name: "Belgium", code: "BE" },
    { name: "Belize", code: "BZ" },
    { name: "Benin", code: "BJ" },
    { name: "Bermuda", code: "BM" },
    { name: "Bhutan", code: "BT" },
    { name: "Bolivia (Plurinational State of)", code: "BO" },
    { name: "Bonaire, Sint Eustatius and Saba", code: "BQ" },
    { name: "Bosnia and Herzegovina", code: "BA" },
    { name: "Botswana", code: "BW" },
    { name: "Bouvet Island", code: "BV" },
    { name: "Brazil", code: "BR" },
    { name: "British Indian Ocean Territory (the)", code: "IO" },
    { name: "Brunei Darussalam", code: "BN" },
    { name: "Bulgaria", code: "BG" },
    { name: "Burkina Faso", code: "BF" },
    { name: "Burundi", code: "BI" },
    { name: "Cabo Verde", code: "CV" },
    { name: "Cambodia", code: "KH" },
    { name: "Cameroon", code: "CM" },
    { name: "Canada", code: "CA" },
    { name: "Cayman Islands (the)", code: "KY" },
    { name: "Central African Republic (the)", code: "CF" },
    { name: "Chad", code: "TD" },
    { name: "Chile", code: "CL" },
    { name: "China", code: "CN" },
    { name: "Christmas Island", code: "CX" },
    { name: "Cocos (Keeling) Islands (the)", code: "CC" },
    { name: "Colombia", code: "CO" },
    { name: "Comoros (the)", code: "KM" },
    { name: "Congo (the Democratic Republic of the)", code: "CD" },
    { name: "Congo (the)", code: "CG" },
    { name: "Cook Islands (the)", code: "CK" },
    { name: "Costa Rica", code: "CR" },
    { name: "Croatia", code: "HR" },
    { name: "Cuba", code: "CU" },
    { name: "Curaçao", code: "CW" },
    { name: "Cyprus", code: "CY" },
    { name: "Czechia", code: "CZ" },
    { name: "Côte d'Ivoire", code: "CI" },
    { name: "Denmark", code: "DK" },
    { name: "Djibouti", code: "DJ" },
    { name: "Dominica", code: "DM" },
    { name: "Dominican Republic (the)", code: "DO" },
    { name: "Ecuador", code: "EC" },
    { name: "Egypt", code: "EG" },
    { name: "El Salvador", code: "SV" },
    { name: "Equatorial Guinea", code: "GQ" },
    { name: "Eritrea", code: "ER" },
    { name: "Estonia", code: "EE" },
    { name: "Eswatini", code: "SZ" },
    { name: "Ethiopia", code: "ET" },
    { name: "Falkland Islands (the) [Malvinas]", code: "FK" },
    { name: "Faroe Islands (the)", code: "FO" },
    { name: "Fiji", code: "FJ" },
    { name: "Finland", code: "FI" },
    { name: "France", code: "FR" },
    { name: "French Guiana", code: "GF" },
    { name: "French Polynesia", code: "PF" },
    { name: "French Southern Territories (the)", code: "TF" },
    { name: "Gabon", code: "GA" },
    { name: "Gambia (the)", code: "GM" },
    { name: "Georgia", code: "GE" },
    { name: "Germany", code: "DE" },
    { name: "Ghana", code: "GH" },
    { name: "Gibraltar", code: "GI" },
    { name: "Greece", code: "GR" },
    { name: "Greenland", code: "GL" },
    { name: "Grenada", code: "GD" },
    { name: "Guadeloupe", code: "GP" },
    { name: "Guam", code: "GU" },
    { name: "Guatemala", code: "GT" },
    { name: "Guernsey", code: "GG" },
    { name: "Guinea", code: "GN" },
    { name: "Guinea-Bissau", code: "GW" },
    { name: "Guyana", code: "GY" },
    { name: "Haiti", code: "HT" },
    { name: "Heard Island and McDonald Islands", code: "HM" },
    { name: "Holy See (the)", code: "VA" },
    { name: "Honduras", code: "HN" },
    { name: "Hong Kong", code: "HK" },
    { name: "Hungary", code: "HU" },
    { name: "Iceland", code: "IS" },
    { name: "India", code: "IN" },
    { name: "Indonesia", code: "ID" },
    { name: "Iran (Islamic Republic of)", code: "IR" },
    { name: "Iraq", code: "IQ" },
    { name: "Ireland", code: "IE" },
    { name: "Isle of Man", code: "IM" },
    { name: "Israel", code: "IL" },
    { name: "Italy", code: "IT" },
    { name: "Jamaica", code: "JM" },
    { name: "Japan", code: "JP" },
    { name: "Jersey", code: "JE" },
    { name: "Jordan", code: "JO" },
    { name: "Kazakhstan", code: "KZ" },
    { name: "Kenya", code: "KE" },
    { name: "Kiribati", code: "KI" },
    { name: "Korea (the Democratic People's Republic of)", code: "KP" },
    { name: "Korea (the Republic of)", code: "KR" },
    { name: "Kuwait", code: "KW" },
    { name: "Kyrgyzstan", code: "KG" },
    { name: "Lao People's Democratic Republic (the)", code: "LA" },
    { name: "Latvia", code: "LV" },
    { name: "Lebanon", code: "LB" },
    { name: "Lesotho", code: "LS" },
    { name: "Liberia", code: "LR" },
    { name: "Libya", code: "LY" },
    { name: "Liechtenstein", code: "LI" },
    { name: "Lithuania", code: "LT" },
    { name: "Luxembourg", code: "LU" },
    { name: "Macao", code: "MO" },
    { name: "Madagascar", code: "MG" },
    { name: "Malawi", code: "MW" },
    { name: "Malaysia", code: "MY" },
    { name: "Maldives", code: "MV" },
    { name: "Mali", code: "ML" },
    { name: "Malta", code: "MT" },
    { name: "Marshall Islands (the)", code: "MH" },
    { name: "Martinique", code: "MQ" },
    { name: "Mauritania", code: "MR" },
    { name: "Mauritius", code: "MU" },
    { name: "Mayotte", code: "YT" },
    { name: "Mexico", code: "MX" },
    { name: "Micronesia (Federated States of)", code: "FM" },
    { name: "Moldova (the Republic of)", code: "MD" },
    { name: "Monaco", code: "MC" },
    { name: "Mongolia", code: "MN" },
    { name: "Montenegro", code: "ME" },
    { name: "Montserrat", code: "MS" },
    { name: "Morocco", code: "MA" },
    { name: "Mozambique", code: "MZ" },
    { name: "Myanmar", code: "MM" },
    { name: "Namibia", code: "NA" },
    { name: "Nauru", code: "NR" },
    { name: "Nepal", code: "NP" },
    { name: "Netherlands (the)", code: "NL" },
    { name: "New Caledonia", code: "NC" },
    { name: "New Zealand", code: "NZ" },
    { name: "Nicaragua", code: "NI" },
    { name: "Niger (the)", code: "NE" },
    { name: "Nigeria", code: "NG" },
    { name: "Niue", code: "NU" },
    { name: "Norfolk Island", code: "NF" },
    { name: "Northern Mariana Islands (the)", code: "MP" },
    { name: "Norway", code: "NO" },
    { name: "Oman", code: "OM" },
    { name: "Pakistan", code: "PK" },
    { name: "Palau", code: "PW" },
    { name: "Palestine, State of", code: "PS" },
    { name: "Panama", code: "PA" },
    { name: "Papua New Guinea", code: "PG" },
    { name: "Paraguay", code: "PY" },
    { name: "Peru", code: "PE" },
    { name: "Philippines (the)", code: "PH" },
    { name: "Pitcairn", code: "PN" },
    { name: "Poland", code: "PL" },
    { name: "Portugal", code: "PT" },
    { name: "Puerto Rico", code: "PR" },
    { name: "Qatar", code: "QA" },
    { name: "Republic of North Macedonia", code: "MK" },
    { name: "Romania", code: "RO" },
    { name: "Russian Federation (the)", code: "RU" },
    { name: "Rwanda", code: "RW" },
    { name: "Réunion", code: "RE" },
    { name: "Saint Barthélemy", code: "BL" },
    { name: "Saint Helena, Ascension and Tristan da Cunha", code: "SH" },
    { name: "Saint Kitts and Nevis", code: "KN" },
    { name: "Saint Lucia", code: "LC" },
    { name: "Saint Martin (French part)", code: "MF" },
    { name: "Saint Pierre and Miquelon", code: "PM" },
    { name: "Saint Vincent and the Grenadines", code: "VC" },
    { name: "Samoa", code: "WS" },
    { name: "San Marino", code: "SM" },
    { name: "Sao Tome and Principe", code: "ST" },
    { name: "Saudi Arabia", code: "SA" },
    { name: "Senegal", code: "SN" },
    { name: "Serbia", code: "RS" },
    { name: "Seychelles", code: "SC" },
    { name: "Sierra Leone", code: "SL" },
    { name: "Singapore", code: "SG" },
    { name: "Sint Maarten (Dutch part)", code: "SX" },
    { name: "Slovakia", code: "SK" },
    { name: "Slovenia", code: "SI" },
    { name: "Solomon Islands", code: "SB" },
    { name: "Somalia", code: "SO" },
    { name: "South Africa", code: "ZA" },
    { name: "South Georgia and the South Sandwich Islands", code: "GS" },
    { name: "South Sudan", code: "SS" },
    { name: "Spain", code: "ES" },
    { name: "Sri Lanka", code: "LK" },
    { name: "Sudan (the)", code: "SD" },
    { name: "Suriname", code: "SR" },
    { name: "Svalbard and Jan Mayen", code: "SJ" },
    { name: "Sweden", code: "SE" },
    { name: "Switzerland", code: "CH" },
    { name: "Syrian Arab Republic", code: "SY" },
    { name: "Taiwan (Province of China)", code: "TW" },
    { name: "Tajikistan", code: "TJ" },
    { name: "Tanzania, United Republic of", code: "TZ" },
    { name: "Thailand", code: "TH" },
    { name: "Timor-Leste", code: "TL" },
    { name: "Togo", code: "TG" },
    { name: "Tokelau", code: "TK" },
    { name: "Tonga", code: "TO" },
    { name: "Trinidad and Tobago", code: "TT" },
    { name: "Tunisia", code: "TN" },
    { name: "Turkey", code: "TR" },
    { name: "Turkmenistan", code: "TM" },
    { name: "Turks and Caicos Islands (the)", code: "TC" },
    { name: "Tuvalu", code: "TV" },
    { name: "Uganda", code: "UG" },
    { name: "Ukraine", code: "UA" },
    { name: "United Arab Emirates (the)", code: "AE" },
    {
      name: "United Kingdom of Great Britain and Northern Ireland (the)",
      code: "GB",
    },
    { name: "United States Minor Outlying Islands (the)", code: "UM" },
    { name: "United States of America (the)", code: "US" },
    { name: "Uruguay", code: "UY" },
    { name: "Uzbekistan", code: "UZ" },
    { name: "Vanuatu", code: "VU" },
    { name: "Venezuela (Bolivarian Republic of)", code: "VE" },
    { name: "Viet Nam", code: "VN" },
    { name: "Virgin Islands (British)", code: "VG" },
    { name: "Virgin Islands (U.S.)", code: "VI" },
    { name: "Wallis and Futuna", code: "WF" },
    { name: "Western Sahara", code: "EH" },
    { name: "Yemen", code: "YE" },
    { name: "Zambia", code: "ZM" },
    { name: "Zimbabwe", code: "ZW" },
  ];

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleInterestChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setFormData({
        ...formData,
        interessi: [...formData.interessi, value],
      });
    } else {
      setFormData({
        ...formData,
        interessi: formData.interessi.filter(
          (interessi) => interessi !== value
        ),
      });
    }
  };

  const checkboxesRef = useRef([]); // Crea un riferimento per le checkbox

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post("/api/users", formData);

      if (response.status === 201) {
        setSuccessMessage(response.data.message);
        // Nascondi successMessage dopo 5 secondi
        setTimeout(() => {
          setSuccessMessage("");
        }, 5000);
        // Reimposta i dati del form
        setFormData({
          nome: "",
          cognome: "",
          azienda: "",
          ruolo: "",
          nazione: "",
          mail: "",
          tel: "",
          web: "",
          interessi: [],
          descrizione: "",
          privacy: false,
        });
        // Deseleziona manualmente le checkbox
        checkboxesRef.current.forEach((checkbox) => {
          checkbox.checked = false;
        });
      } else {
        setErrorMessage(
          "Something went wrong! Please verify your data and try again. First of all verify your email address has not been registered yet."
        );
        setTimeout(() => {
          setErrorMessage("");
        }, 5000);
      }
    } catch (error) {
      console.error(error);
      // Imposta un messaggio di errore generico in caso di altri errori
      setErrorMessage(
        "Something went wrong! Please verify your data and try again. First of all verify your email address has not been registered yet."
      );
      setTimeout(() => {
        setErrorMessage("");
      }, 5000);
    }
  };

  return (
    <>
      <NavBar />
      <div className="container mt-3">
        <div className="row">
          <div className="col-12 mt-3 mb-3 p-3">
            <div className="card">
              <div className="card-body">
                <h1>Your data</h1>
                <p className="alert alert-info">
                  Fill the form with your data to be connected with Italian
                  entrepreneurs in choosen sector(s).
                  <br />
                  We will contact you within a few weeks with a list of Italian
                  entrepreneurs selected by our team.
                  <br />
                  <b>Keep in touch!</b>
                </p>
                <div className="text-end">
                  <i className="fa-solid fa-circle-exclamation text text-danger"></i>{" "}
                  required field &nbsp; &nbsp; &nbsp;
                  <i className="fa-solid fa-check text text-success"></i>{" "}
                  optional field
                </div>
                <form className="was-validated" onSubmit={handleSubmit}>
                  <div className="row justify-content-md-center">
                    {/* Campi del form */}
                    <div className="col-md-4 col-xs-12 mb-3">
                      <label htmlFor="nome">
                        First Name<b className="text-danger">*</b>:
                      </label>
                      <input
                        type="text"
                        id="nome"
                        name="nome"
                        value={formData.nome}
                        onChange={handleChange}
                        required
                        className="form-control"
                      />
                    </div>{" "}
                    <div className="col-md-4 col-xs-12 mb-3">
                      <label htmlFor="cognome">
                        Last Name<b className="text-danger">*</b>:
                      </label>
                      <input
                        type="text"
                        id="cognome"
                        name="cognome"
                        value={formData.cognome}
                        onChange={handleChange}
                        required
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="row justify-content-md-center">
                    <div className="col-md-3 col-xs-12 mb-3">
                      <label htmlFor="azienda">Company:</label>
                      <input
                        type="text"
                        id="azienda"
                        name="azienda"
                        value={formData.azienda}
                        onChange={handleChange}
                        className="form-control"
                      />
                    </div>{" "}
                    <div className="col-md-2 col-xs-12 mb-3">
                      <label htmlFor="firstName">Role:</label>
                      <input
                        type="text"
                        id="ruolo"
                        name="ruolo"
                        value={formData.ruolo}
                        onChange={handleChange}
                        className="form-control"
                      />
                    </div>{" "}
                    <div className="col-md-3 col-xs-12 mb-3">
                      <label htmlFor="nazione">
                        Country<b className="text-danger">*</b>:
                      </label>
                      <select
                        id="nazione"
                        name="nazione"
                        value={formData.country}
                        onChange={handleChange}
                        required
                        className="form-select"
                      >
                        <option value="">Select your country</option>
                        {countries.map((country) => (
                          <option key={country.code} value={country.name}>
                            {country.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="row justify-content-md-center">
                    <div className="col-md-4 col-xs-12 mb-3">
                      <label htmlFor="mail">
                        Email address<b className="text-danger">*</b>:
                      </label>
                      <input
                        type="email"
                        id="mail"
                        name="mail"
                        value={formData.mail}
                        onChange={handleChange}
                        required
                        className="form-control"
                      />
                    </div>{" "}
                    <div className="col-md-4 col-xs-12 mb-3">
                      <label htmlFor="tel">
                        Phone<b className="text-danger">*</b>:
                      </label>
                      <input
                        type="text"
                        id="tel"
                        name="tel"
                        value={formData.tel}
                        onChange={handleChange}
                        required
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="row justify-content-md-center">
                    <div className="col-md-8 col-xs-12 mb-3">
                      <label htmlFor="web">Website:</label>
                      <input
                        type="text"
                        id="web"
                        name="web"
                        value={formData.web}
                        onChange={handleChange}
                        className="form-control"
                        placeholder="https://"
                      />
                    </div>
                  </div>
                  <div className="row justify-content-md-center">
                    <div className="col-md-8 col-xs-12">
                      <h3>
                        <b className="text-danger">*</b>Sector(s) of Interest:
                      </h3>
                      <div className="row">
                        <div className="col-md-6 col-xs-12 mb-3">
                          <input
                            type="checkbox"
                            id="interessi1"
                            name="interessi1"
                            value="Food & Beverage"
                            onChange={handleInterestChange}
                            ref={(el) => (checkboxesRef.current[0] = el)} // Aggiungi il riferimento
                          />
                          <label htmlFor="interessi1">
                            {" "}
                            &nbsp; Food & Beverage
                          </label>
                        </div>
                        <div className="col-md-6 col-xs-12 mb-3">
                          <input
                            type="checkbox"
                            id="interessi2"
                            name="interessi2"
                            value="Immobiliare"
                            onChange={handleInterestChange}
                            ref={(el) => (checkboxesRef.current[1] = el)} // Aggiungi il riferimento
                          />
                          <label htmlFor="interessi2">
                            {" "}
                            &nbsp; Luxury Real Estate
                          </label>
                        </div>
                        <div className="col-md-6 col-xs-12 mb-3">
                          <input
                            type="checkbox"
                            id="interessi3"
                            name="interessi3"
                            value="Viaggi"
                            onChange={handleInterestChange}
                            ref={(el) => (checkboxesRef.current[2] = el)} // Aggiungi il riferimento
                          />
                          <label htmlFor="interessi3">
                            {" "}
                            &nbsp; Bespoke travel experiences
                          </label>
                        </div>
                        <div className="col-md-6 col-xs-12 mb-3">
                          <input
                            type="checkbox"
                            id="interessi4"
                            name="interessi4"
                            value="Artigianato"
                            onChange={handleInterestChange}
                            ref={(el) => (checkboxesRef.current[3] = el)} // Aggiungi il riferimento
                          />
                          <label htmlFor="interessi4">
                            {" "}
                            &nbsp; Artisanal crafts & art
                          </label>
                        </div>
                        <div className="col-md-6 col-xs-12 mb-3">
                          <input
                            type="checkbox"
                            id="interessi5"
                            name="interessi5"
                            value="Informatica"
                            onChange={handleInterestChange}
                            ref={(el) => (checkboxesRef.current[4] = el)} // Aggiungi il riferimento
                          />
                          <label htmlFor="interessi5">
                            {" "}
                            &nbsp; IT, Services & Professional Networking
                          </label>
                        </div>
                        <div className="col-md-6 col-xs-12 mb-3">
                          <input
                            type="checkbox"
                            id="interessi6"
                            name="interessi6"
                            value="Benessere"
                            onChange={handleInterestChange}
                            ref={(el) => (checkboxesRef.current[5] = el)} // Aggiungi il riferimento
                          />
                          <label htmlFor="interessi6">
                            {" "}
                            &nbsp; Wellness & Healing Traditions
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row justify-content-md-center">
                    <div className="col-md-8 col-xs-12 mb-3">
                      <label htmlFor="interestDescription">
                        Brief description of your interest:
                      </label>
                      <textarea
                        id="descrizione"
                        name="descrizione"
                        value={formData.descrizione}
                        onChange={handleChange}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="row justify-content-md-center">
                    <div className="col-md-8 col-xs-12 mb-3">
                      <input
                        type="checkbox"
                        id="privacy"
                        name="privacy"
                        checked={formData.privacy}
                        onChange={handleChange}
                        required
                      />

                      <label htmlFor="privacy">
                        &nbsp; <b className="text-danger">*</b> I consent to the{" "}
                        <a href="#!" onClick={() => setShowModal(true)}>
                          processing of my personal data
                        </a>
                      </label>
                    </div>
                  </div>
                  <div className="row justify-content-md-center">
                    <div className="col-md-8 col-xs-12 mb-3">
                      <input
                        type="checkbox"
                        id="callback"
                        name="callback"
                        checked={formData.callback}
                        onChange={handleChange}
                      />

                      <label htmlFor="callback">
                        &nbsp; I agree to be contacted back from
                        MeetNewPartners.com
                      </label>
                    </div>
                  </div>
                  <div className="row justify-content-md-center">
                    <div
                      className="col-md-8 col-xs-12 mb-3"
                      style={{ fontSize: "0.8rem" }}
                    >
                      <b className="text-danger">*</b> Required fields
                      <br />
                      <br />
                      {successMessage && ( // Mostra il messaggio se successMessage non è vuota
                        <p className="alert alert-success">{successMessage}</p>
                      )}
                      {errorMessage && ( // Mostra il messaggio di errore se errorMessage non è vuota
                        <p className="alert alert-danger">
                          <b>Pay attention!</b>
                          <br />
                          {errorMessage}
                        </p>
                      )}
                      <button
                        type="submit"
                        className="btn btn-secondary btn-lg w-100"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        {showModal && (
          <div
            className="modal fade bd-example-modal-xl show" // Aggiungi "show" per mostrare la modale
            tabIndex="-1"
            role="dialog"
            aria-labelledby="myExtraLargeModalLabel"
            aria-hidden="true"
            style={{ display: "block" }} // Aggiungi display: 'block' per mostrare la modale
          >
            <div className="modal-dialog modal-xl" role="document">
              <div className="modal-content" style={{ color: "#012942" }}>
                <div className="modal-header">
                  <h5 className="modal-title" id="myModalLabel">
                    Privacy Policy
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    onClick={() => setShowModal(false)}
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <p>
                    In this privacy statement, we explain what we do with the
                    data we obtain about you via{" "}
                    <a href="https://meetnewpartners.com">
                      https://meetnewpartners.com
                    </a>
                    . We recommend you carefully read this statement. In our
                    processing we comply with the requirements of privacy
                    legislation. That means, among other things, that:
                  </p>
                  <ul>
                    <li>
                      we clearly state the purposes for which we process
                      personal data. We do this by means of this privacy
                      statement;
                    </li>
                    <li>
                      we aim to limit our collection of personal data to only
                      the personal data required for legitimate purposes;
                    </li>
                    <li>
                      we first request your explicit consent to process your
                      personal data in cases requiring your consent;
                    </li>
                    <li>
                      we take appropriate security measures to protect your
                      personal data and also require this from parties that
                      process personal data on our behalf;
                    </li>
                    <li>
                      we respect your right to access your personal data or have
                      it corrected or deleted, at your request.
                    </li>
                  </ul>
                  <p>
                    If you have any questions, or want to know exactly what data
                    we keep of you, please contact us.
                  </p>
                  <h6>1. Purpose, data and retention period</h6>We may collect
                  or receive personal information for a number of purposes
                  connected with our business operations which may include the
                  following: (click to expand)
                  <div style={{ paddingLeft: "2vh" }}>
                    <div>
                      <h6>
                        1.1 Contact - Through phone, mail, email and/or webforms
                      </h6>
                    </div>
                    <p>For this purpose we use the following data:</p>
                    <p></p>
                    <ul>
                      <li>A first and last name</li>
                      <li>
                        A home or other physical address, including street name
                        and name of a city or town
                      </li>
                      <li>An email address</li>
                      <li>A telephone number</li>
                    </ul>
                    <p></p>
                    <p>
                      The basis on which we may process these data is:{" "}
                      <a
                        target="_blank"
                        rel="noopener noreferrer nofollow"
                        href="https://cookiedatabase.org/legal-bases/#consent"
                      >
                        Upon the provision of consent.
                      </a>
                    </p>
                    <p>
                      Retention period: we retain this data until the service is
                      terminated.
                    </p>
                  </div>
                  <h6>2. Cookies</h6>
                  <p>
                    Our website uses cookies. For more information about
                    cookies, please refer to our{" "}
                    <a href="https://meetnewpartners.com/cookie-policy/">
                      Cookie Policy
                    </a>
                    .&nbsp;
                  </p>
                  <h6>3. Disclosure practices</h6>
                  <p></p>
                  <p>
                    We disclose personal information if we are required by law
                    or by a court order, in response to a law enforcement
                    agency, to the extent permitted under other provisions of
                    law, to provide information, or for an investigation on a
                    matter related to public safety.
                  </p>
                  <p>
                    If our website or organisation is taken over, sold, or
                    involved in a merger or acquisition, your details may be
                    disclosed to our advisers and any prospective purchasers and
                    will be passed on to the new owners.
                  </p>
                  <p></p>
                  <h6>4. Security</h6>
                  <p>
                    We are committed to the security of personal data. We take
                    appropriate security measures to limit abuse of and
                    unauthorised access to personal data. This ensures that only
                    the necessary persons have access to your data, that access
                    to the data is protected, and that our security measures are
                    regularly reviewed.
                  </p>
                  <h6>5. Third-party websites</h6>
                  <p>
                    This privacy statement does not apply to third-party
                    websites connected by links on our website. We cannot
                    guarantee that these third parties handle your personal data
                    in a reliable or secure manner. We recommend you read the
                    privacy statements of these websites prior to making use of
                    these websites.
                  </p>
                  <h6>6. Amendments to this privacy statement</h6>
                  <p>
                    We reserve the right to make amendments to this privacy
                    statement. It is recommended that you consult this privacy
                    statement regularly in order to be aware of any changes. In
                    addition, we will actively inform you wherever possible.
                  </p>
                  <h6>7. Accessing and modifying your data</h6>
                  <p>
                    If you have any questions or want to know which personal
                    data we have about you, please contact us. You can contact
                    us by using the information below. You have the following
                    rights:
                  </p>
                  <ul>
                    <li>
                      You have the right to know why your personal data is
                      needed, what will happen to it, and how long it will be
                      retained for.
                    </li>
                    <li>
                      Right of access: You have the right to access your
                      personal data that is known to us.
                    </li>
                    <li>
                      Right to rectification: you have the right to supplement,
                      correct, have deleted or blocked your personal data
                      whenever you wish.
                    </li>
                    <li>
                      If you give us your consent to process your data, you have
                      the right to revoke that consent and to have your personal
                      data deleted.
                    </li>
                    <li>
                      Right to transfer your data: you have the right to request
                      all your personal data from the controller and transfer it
                      in its entirety to another controller.
                    </li>
                    <li>
                      Right to object: you may object to the processing of your
                      data. We comply with this, unless there are justified
                      grounds for processing.
                    </li>
                  </ul>
                  <p>
                    Please make sure to always clearly state who you are, so
                    that we can be certain that we do not modify or delete any
                    data of the wrong person.
                  </p>
                  <h6>8. Submitting a complaint</h6>
                  <p>
                    If you are not satisfied with the way in which we handle (a
                    complaint about) the processing of your personal data, you
                    have the right to submit a complaint to the Information
                    Commissioner's Office
                  </p>
                  <h6>9. Children</h6>
                  <p>
                    Our website is not designed to attract children and it is
                    not our intent to collect personal data from children under
                    the age of consent in their country of residence. We
                    therefore request that children under the age of consent do
                    not submit any personal data to us.
                  </p>
                  <h6>10. Contact details</h6>
                  <p>
                    FD s.r.l.
                    <br />
                    Strada dell’Osteria 82, 06050 - Collazzone (PG)
                    <br />
                    VAT #: IT-03726350543
                    <br />
                    Website:{" "}
                    <a href="https://meetnewpartners.com">
                      https://meetnewpartners.com
                    </a>{" "}
                    <br />
                    Email: info@meetnewpartners.com <br />
                    Phone number: +39 348 3314376
                  </p>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => setShowModal(false)} // Rimuovi data-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        {/* Aggiungi un div per l'overlay */}
        {showModal && (
          <div
            className="modal-backdrop fade show" // Aggiungi "show" per mostrare l'overlay
            onClick={() => setShowModal(false)} // Chiudi la modale cliccando sull'overlay
          ></div>
        )}
      </div>
    </>
  );
};

export default UserForm;
